// vendor
import { useMemo, useState, useEffect } from "react";
import { compareDesc, sub } from "date-fns";
import { useNavigate } from "react-router-dom";

// material ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";

// internal
import Carousel from "../../components/Carousel";

// internal
import {
  FileThumbnail,
  WalkThrough,
  WalkThroughStepsEnum,
} from "../../components";
import { Widget, WidgetStatus, WidgetStatusEnum } from "../../types";
import { formatTimestampToLocal, formatCurrency } from "../../utils";
import { LoggedInRoutesEnum, WidgetViewEnum } from "../../constants";

export function WidgetPanel({
  widgetData,
  onActivate,
  activationInProgress,
}: {
  widgetData: Widget;
  onActivate: (status: WidgetStatus) => void;
  activationInProgress: boolean;
}) {
  const [open, setOpen] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [activeImgVal, setActiveImgVal] = useState(0);
  const navigate = useNavigate();

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (widgetData?.images) {
      setImages(widgetData.images);
    }
  }, [widgetData]);

  if (!widgetData) {
    return null;
  }

  const renderButton = (widgetStatus: WidgetStatus, loading: boolean) => {
    if (loading) {
      return <CircularProgress />;
    }

    if (
      widgetStatus === WidgetStatusEnum.QUEUED ||
      widgetStatus === WidgetStatusEnum.NEW
    ) {
      return (
        <Button
          variant={"text"}
          size="large"
          startIcon={<PowerSettingsNewIcon />}
          onClick={() => onActivate(WidgetStatusEnum.BUILDING)}
          color="primary"
          disabled={false}
        >
          {"Build & Activate"}
        </Button>
      );
    }

    if (widgetStatus === WidgetStatusEnum.BUILDING) {
      return (
        <Button
          variant={"text"}
          size="large"
          startIcon={<PowerSettingsNewIcon />}
          onClick={() => {}}
          color="primary"
          disabled={true}
        >
          {"Build & Activate"}
        </Button>
      );
    }

    if (widgetStatus === WidgetStatusEnum.FAILED) {
      return (
        <Button
          variant={"text"}
          size="large"
          startIcon={<PowerSettingsNewIcon />}
          onClick={() => onActivate(WidgetStatusEnum.BUILDING)}
          color="error"
          disabled={false}
        >
          {"Rebuild"}
        </Button>
      );
    }

    if (widgetStatus === WidgetStatusEnum.INACTIVE) {
      return (
        <Button
          variant={"text"}
          size="large"
          startIcon={<PowerSettingsNewIcon />}
          onClick={() => onActivate(WidgetStatusEnum.ACTIVE)}
          color="primary"
          disabled={false}
        >
          {"Reactivate"}
        </Button>
      );
    }

    if (widgetStatus === WidgetStatusEnum.ACTIVE) {
      return (
        <Button
          variant={"text"}
          size="large"
          startIcon={<PowerSettingsNewIcon />}
          onClick={() => onActivate(WidgetStatusEnum.INACTIVE)}
          color="error"
          disabled={false}
        >
          {"Deactivate"}
        </Button>
      );
    }

    return null;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <WalkThrough
          locationID={WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_ACTIVE}
        >
          {renderButton(widgetData.widget_status, activationInProgress)}
        </WalkThrough>
      </Grid>
      <Grid item xs={6} sx={{ textAlign: "right" }}>
        <WalkThrough
          locationID={WalkThroughStepsEnum.WIDGET_LIBRARY_DISPLAY_PAGE_EDIT}
        >
          <Button
            variant="text"
            size="large"
            startIcon={<EditIcon />}
            onClick={() =>
              navigate(
                `${LoggedInRoutesEnum.EDIT_WIDGET_PREFIX}/${widgetData.uuid}`,
              )
            }
          >
            {WidgetViewEnum.EDIT_CTA_LABEL}
          </Button>
        </WalkThrough>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <Carousel
            width="300px"
            images={images}
            onClick={(i: number) => {
              setActiveImgVal(i);
              setOpen(true);
            }}
            activeImg={activeImgVal}
          />
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "612px",
                bgcolor: "background.paper",
                border: "none",
                boxShadow: 24,
                padding: "6px",
              }}
            >
              <Carousel
                width="600px"
                images={images}
                activeImg={activeImgVal}
              />
            </Box>
          </Modal>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              padding: "20px",
              border: "1px solid #d2d2d2",
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {WidgetViewEnum.WIDGET_LIVE_LABEL}
              </Typography>
              <Typography
                variant="h5"
                sx={{ marginLeft: "auto", textTransform: "capitalize" }}
              >
                {widgetData.widget_status}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {WidgetViewEnum.AI_ACTIVE_LABEL}
              </Typography>

              <Typography variant="h5" sx={{ marginLeft: "auto" }}>
                {widgetData.is_ai_enabled ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {WidgetViewEnum.AGE_RESTRICTED_LABEL}
              </Typography>
              <Typography
                variant="h5"
                sx={{ marginLeft: "auto", paddingBottom: "10px" }}
              >
                {widgetData.is_age_restricted ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: "10px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {WidgetViewEnum.INDEXING_ENABLED_LABEL}
              </Typography>
              <Typography
                variant="h5"
                sx={{ marginLeft: "auto", paddingBottom: "10px" }}
              >
                {widgetData.is_indexing_enabled ? "Yes" : "No"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  variant="h6"
                  component="h6"
                  sx={{ fontWeight: "bold" }}
                >
                  {WidgetViewEnum.AVAILABILITY_SUB_HEADER}
                </Typography>
                <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                  Available to the public through the search page
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "auto" }}>
                <FormControlLabel
                  disabled
                  control={<Checkbox checked={widgetData.is_public} />}
                  label={WidgetViewEnum.ONLINE_CHECKBOX_LABEL}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "20px" }}>
        <Typography variant="h3" component="h3">
          {widgetData.widget_title}
        </Typography>
        <Typography variant="h6" component="h6">
          {widgetData.widget_description}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px" }}>
        <Typography variant="h6" component="h6" sx={{ fontWeight: "bold" }}>
          {WidgetViewEnum.DEAL_SUB_HEADER}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "50%",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold", paddingRight: "6px" }}
            >
              {WidgetViewEnum.OFFER_LABEL}
            </Typography>
            {widgetData.widget_details.widget_offer && (
              <Typography variant="body1" component="p">
                {widgetData.widget_details.widget_offer}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold", paddingRight: "6px" }}
            >
              {WidgetViewEnum.OFFER_CODE_LABEL}
            </Typography>
            {widgetData.widget_details.widget_offer_code && (
              <Typography variant="body1" component="p">
                {widgetData.widget_details.widget_offer_code}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold", paddingRight: "6px" }}
            >
              {WidgetViewEnum.PRICE_LABEL}
            </Typography>
            {widgetData.widget_details.widget_price && (
              <Typography variant="body1" component="p">
                {`$${formatCurrency(widgetData.widget_details.widget_price)}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold", paddingRight: "6px" }}
            >
              {WidgetViewEnum.START_LABEL}
            </Typography>
            {widgetData.widget_details.start_date && (
              <Typography variant="body1" component="p">
                {formatTimestampToLocal(widgetData.widget_details.start_date)}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: "bold", paddingRight: "6px" }}
            >
              {WidgetViewEnum.END_LABEL}
            </Typography>
            {widgetData.widget_details.end_date && (
              <Typography variant="body1" component="p">
                {formatTimestampToLocal(widgetData.widget_details.end_date)}
              </Typography>
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px" }}>
        <Typography variant="h6" component="h6" sx={{ fontWeight: "bold" }}>
          {WidgetViewEnum.TRAINING_SUB_HEADER}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            paddingBottom: "42px",
          }}
        >
          {widgetData.training_files.map((file) => (
            <FileThumbnail key={file.uuid} fileName={file.file_name} />
          ))}
        </Box>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop: "12px" }}>
        {widgetData.locations.map((loc) => (
          <Box key={loc.name}>
            <FormControlLabel
              disabled
              control={<Checkbox checked />}
              label={`${loc.name}, ${loc.street_address}, ${loc.city}, ${loc.state}, ${loc.zip_code}`}
            />
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}
